import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';
import Link from '../link/link';
import Styles from './news.module.scss';

const defaultQuery = graphql`
    query News {
        articles: allPrismicNewsArticle(limit: 3, sort: {fields: first_publication_date, order: ASC}) {
            nodes {
                uid
                first_publication_date
                publish_date: first_publication_date(formatString: "DD.MM.YYYY")
                data {
                    image {
                        thumbnail {
                            localFile  {
                                childImageSharp {
                                    fluid(maxWidth: 600) {
                                        ...GatsbyImageSharpFluid_withWebp_noBase64
                                    }
                                }
                            }
                        }
                    }
                    title {
                        text
                    }
                    shorttext {
                        text
                    }
                }
            }
        }
    }
`

export default props => {
    const query = useStaticQuery(defaultQuery);
    const articles = props.articles || query.articles;
    
    if (!Array.isArray(articles.nodes) || articles.nodes.length === 0) {
        return '';
    }

    return (
        <section className={`py-5`}>
            <div className="container">
                <div className="row">
                    {
                        articles.nodes.map((node, index) => (
                            <div className="col-12 col-md-6 col-lg-4 mb-5" key={index}>
                                <div className={`${Styles.post}`}>
                                    <Img fluid={node.data.image.thumbnail.localFile.childImageSharp.fluid}
                                        objectFit="cover"
                                        objectPosition="50% 50%"
                                        alt=""></Img>
                                    <h2 className="mt-2">{node.data.title.text}</h2>
                                    <p>{node.data.shorttext.text}</p>
                                    <Link to={`/news/${node.uid}`} className="unstyled color-gold stretched-link">Mehr erfahren</Link>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {
                    !props.hideMoreButton ? (
                        <div className="row">
                            <div className="col-12 text-center py-4">
                                <Link className="west-button unstyled" to="/news">Mehr erfahren</Link>
                            </div>
                        </div>
                    ) : ''
                }
            </div>
        </section>
    );
}
