import { graphql } from 'gatsby';
import * as React from 'react';
import ContactInfo from '../components/global/contact-info/contact-info';
import FullWidthImage from '../components/global/full-width-image/full-width-image';
import GridGallery from '../components/global/grid-gallery/grid-gallery';
import HeaderAndText from '../components/global/header-and-text/header-and-text';
import Layout from '../components/global/layout/layout';
import News from '../components/global/news/news';

export const pageQuery = graphql`
  query HochzeitQuery {
    site {
      siteMetadata {
        titleSuffix
        titleDivider
      }
    }
    prismicMarriagePage {
      data {
        gallery {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  originalName
                }
              }
              publicURL
            }
          }
        }
        title_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        rooms_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        partners {
          link {
            uid
            document {
              ... on PrismicNewsArticle {
                uid
                publish_date: first_publication_date(formatString: "DD.MM.YYYY")
                data {
                  title {
                    text
                  }
                  shorttext {
                    text
                  }
                  image {
                    thumbnail {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 2400) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                  body {
                    ... on PrismicNewsArticleBodyText {
                      slice_type
                      primary {
                        text {
                          html
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => {
  const galleryData = props.data.prismicMarriagePage.data.gallery.map(node => {
    return {
      fluid: node.image.localFile.childImageSharp.fluid,
      publicURL: node.image.localFile.publicURL
    };
  })
  return (
    <Layout wrapperClassName="bg-pattern-gold"
            title="Hochzeit"
            description="Die einzigartige romantische Atmosphäre von Schloss Westerburg schafft den idealen Rahmen für ein ganz besonderes Ereignis - Ihre Hochzeit."
            titleSuffix={props.data.site.siteMetadata.titleSuffix}
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <FullWidthImage
        tag="header"
        fluid={props.data.prismicMarriagePage.data.title_image.localFile.childImageSharp.fluid}
        link={{
          text: 'Historisches Trauzimmer'
        }}
        // customInnerHtml="<div classname='container' style='    position: absolute;    left: 7rem;    bottom: 4rem;'><div classname='row'><div classname='col' style='    background-color: white;    color: #9f7653;    padding: 1rem;    border-radius: 1.1rem;    transform: rotate(-8deg);    font-weight: bold;    font-size: 2rem;    line-height: 2.4rem;'>    <span>Historisches<br>Trauzimmer</span></div></div></div>"
      />
      <HeaderAndText
        heading="Ihre Hochzeit auf Schloss Westerburg"
        text={[
          "Die einzigartige romantische Atmosphäre von Schloss Westerburg schafft den idealen Rahmen für ein ganz besonderes Ereignis - Ihre Hochzeit. Ob es das kleine Hochzeitsessen nach der standesamtlichen Trauung sein soll oder das große Hochzeitsfest, gerne erfüllen wir Ihre individuellen Wünsche. Natürlich können Sie sich auf Schloss Westerburg auch standesamtlich trauen lassen. Und wo lässt sich diese feierliche Zeremonie besser begehen als in einer mittelalterlichen Schlosskapelle.",
        ]}
      />
      <GridGallery images={galleryData}/>
      <HeaderAndText
        heading="Wir geben Ihrer Hochzeit den perfekten Rahmen"
        text={[
          "Die herrschaftlichen Räumlichkeiten sowie die romantische Außenanlage bieten in historischem Ambiente bis zu 100 Gästen Platz. Auch die kulinarischen Köstlichkeiten unserer Küche lassen keine Wünsche offen. Lassen Sie sich am schönsten Tag in Ihrem Leben von uns verwöhnen. Gerne laden wir Sie ein, unser Haus kennen zu lernen und Sie in einem persönlichen Gespräch zu beraten."
        ]}
      />
      <News articles={{ nodes: props.data.prismicMarriagePage.data.partners.map(data => data.link.document) }} hideMoreButton={true}/>
      <FullWidthImage link={{ to: '/raeumlichkeiten', text: 'Räumlichkeiten auf Schloss Westerburg' }} tag="section" fluid={props.data.prismicMarriagePage.data.rooms_image.localFile.childImageSharp.fluid}/>
      <HeaderAndText
        heading="Auf diese Partner können Sie vertrauen"
        text={[
          "Für unsere Hochzeiten können wir auf ein großes und vertrauensvolles Partner-Netzwerk zurückgreifen. Hier finden Sie eine Auswahl der Unternehmen, mit denen wir gerne zusammenarbeiten."
        ]}
        link={{
          to: '/partner',
          text: 'Unsere Partner entdecken'
        }}
      />
      <ContactInfo bgClass="bg-pattern-green"/>
    </Layout>
  );
}
